// extracted by mini-css-extract-plugin
export var contacts__awardsCard = "bg_kL";
export var contacts__awardsCardCol = "bg_kP";
export var contacts__awardsCardLogo = "bg_kM";
export var contacts__awardsCardTitle = "bg_kN";
export var contacts__awardsCardWrapper = "bg_kK";
export var contacts__br = "bg_kQ";
export var contacts__infoCard = "bg_kG";
export var contacts__infoCardBody = "bg_kJ";
export var contacts__infoCardCol = "bg_kF";
export var contacts__infoCardTitle = "bg_kH";
export var contacts__infoCardWrapper = "bg_kD";
export var contacts__withOutBorderBottom = "bg_kR";